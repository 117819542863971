import React, { useEffect, useState } from "react";
// import EventCalendar from '../components/EventCalendar'; 
import b1 from '../images/b1.jpg';
import b2 from '../images/b2.jpg';
import b3 from '../images/b3.jpg';
import b4 from '../images/b4.jpg';
import b5 from '../images/b5.jpg';
import b6 from '../images/b6.jpg';
import b7 from '../images/b7.jpg';
import b8 from '../images/b8.jpg';
import b9 from '../images/b9.jpg'; 
// import gen from '../images/SATHYABAMA (4).png'
// import digi from '../images/Digital Art Competition Poster.png'
import './Apex.css';
// import vai from '../images/w1.jpg';
// import grp2 from '../images/w9.jpg';
import synergy from '../images/synergy.jpg'

// const events = [
//   {
//     date: '2024-08-01',
//     name: 'DIGTAL ART',
//     brochure: digi,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
   
    
//   },
//   {
//     date: '2024-08-01',
//     name: 'DEEP-DIVE INTO GEN-AI',
//     brochure: gen,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
   
    
//   },
//   {
//     date: '2024-04-08',
//     name: 'INAUGURAL CEREMONY',
//     brochure: b1,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'CORE',
    
//   },
//   {
//     date: '2024-07-02',
//     name: 'Mending the Minds of Youngsters',
//     brochure: b4,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-06-10',
//     name: 'Virtual Horizons',
//     brochure: b3,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-07-04',
//     name: 'Generative AI and transformers',
//     brochure: b6,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-07-08',
//     name: 'Peer-peer hands on session on mobile app development',
//     brochure: b7,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-04-15',
//     name: 'Creators of Metaverse',
//     brochure: b2,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-07-08',
//     name: 'Foundation and Revolution of blockchain technology',
//     brochure: b2,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-07-09',
//     name: 'Ai based IOT product development and design',
//     brochure: b2,
//     gallery: ['path/to/image1.jpg', 'path/to/image2.jpg'],
//     uniqueId: 'NON-CORE',
    
//   },
//   {
//     date: '2024-07-09',
//     name: 'Azure Cascade',
//     brochure: b8,
//     gallery: [vai, grp2],
//     uniqueId: 'CORE',
    
//   },
 
// ];

const Fest = () => {
  // const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // useEffect(() => {
  //   const second = 1000;
  //   const minute = second * 60;
  //   const hour = minute * 60;
  //   const day = hour * 24;

  //   const updateCountdown = () => {
  //     const now = new Date();
  //     let targetTime;

  //     // Calculate today's 4 PM
  //     const today4PM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0, 0);

  //     if (now > today4PM) {
  //       // If current time is past 4 PM, set target time to 4 PM tomorrow
  //       targetTime = new Date(today4PM.getTime() + day);
  //     } else {
  //       // Otherwise, set target time to today's 4 PM
  //       targetTime = today4PM;
  //     }

  //     const distance = targetTime.getTime() - now.getTime();

  //     if (distance > 0) {
  //       setTimeLeft({
  //         days: Math.floor(distance / day),
  //         hours: Math.floor((distance % day) / hour),
  //         minutes: Math.floor((distance % hour) / minute),
  //         seconds: Math.floor((distance % minute) / second),
  //       });
  //     } else {
  //       setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  //       clearInterval(timer);
  //     }
  //   };

  //   const timer = setInterval(updateCountdown, 1000);

   
  //   updateCountdown();

  //   return () => clearInterval(timer);
  // }, []);
  return (
    <>
      <div className='sl1'>
        <div className='slider'>
          <div className='slide-track'>
            {[b1, b2, b3, b4, b5, b6, b7, b8, b9, b1, b2, b3, b4, b5, b6, b7, b8, b9].map((src, index) => (
              <div className='slide' key={index}>
                <img src={src} alt={`slide-${index}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='Time'>
  <section className='Time-wrapper'>
    <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
    <div className="events-containerz">
      <div id='Time-title1' className="eventz">
      
      </div>
      <div className="event-cards-container">
      {/* Card 1 - Predictive Challenge */}
      <div className="event-card">
        <h2 className="chapter-title">SIST ACM SIGAI STUDENT CHAPTER</h2>
        <p className="presents">PRESENTS</p>
        <h1 className="challenge-title">
          PREDICTIVE <span className="challenge-subtitle">CHALLENGE</span>
        </h1>

        <div className="event-details">
          <p>📅 <strong>19<sup>th</sup> March, 2025</strong></p>
          <p>📍 <strong>Seminar Hall 3, SCAS</strong></p>
          <p>⏰ <strong>9:00 AM - 3:00 PM IST</strong></p>
        </div>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf8NHykrWxZkSY2ftvo49X1m1OtmDcXEZRxUo5_Gw1W_pwR0Q/viewform?usp=header"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="register-btn">REGISTER NOW</button>
        </a>
      </div>

      {/* Card 2 - AI Symposium */}
      <div className="event-card">
        <h2 className="chapter-title">SIST ACM SIGAI STUDENT CHAPTER</h2>
        <p className="presents">PRESENTS</p>
        <h1 className="challenge-title">
          AI <span className="challenge-subtitle">SYMPHONY</span>
        </h1>

        <div className="event-details">
          <p>📅 <strong>29<sup>th</sup> March, 2025</strong></p>
          <p>📍 <strong>Seminar Hall 3, SCAS</strong></p>
          <p>⏰ <strong>9:00 AM - 3:00 PM IST</strong></p>
        </div>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScZBd13Xet4J_6l8ZOq-fZxIFlOsXU37kwXLMENcvhg3dX60g/viewform?usp=dialog"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="register-btn">REGISTER NOW</button>
        </a>
      </div>

      {/* Card 3 - Data Engineering Workshop */}
      <div className="event-card">
        <h2 className="chapter-title">SIST ACM SIGAI STUDENT CHAPTER</h2>
        <p className="presents">PRESENTS</p>
        <h1 className="challenge-title">
          DATA <span className="challenge-subtitle">DECODERS</span>
        </h1>

        <div className="event-details">
          <p>📅 <strong>22<sup>nd</sup> March, 2025</strong></p>
          <p>📍 <strong>Seminar Hall 2, SCAS</strong></p>
          <p>⏰ <strong>9:00 AM - 3:00 PM IST</strong></p>
        </div>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd-7PuXM4Tp0rVEHOGUulVp75hL3WsVvcUg4Rh3N4YHyMBmxQ/viewform?usp=dialog"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="register-btn">REGISTER NOW</button>
        </a>
      </div>
    </div>
      {/* <h1 id="headline">Stay tuned for upcoming Events</h1> */}
     
      
    </div>
  </section>
</div>



      {/* <div className='calendar-container'>
        <EventCalendar events={events} />
      </div> */}
    </>
  );
};

export default Fest;
