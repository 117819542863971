import React from 'react';
// import {motion as m} from "framer-motion";
// import { Link } from 'react-router-dom';
// import {fadeIn} from '../components/transitions'
// import log from '../images/videog.jpg'
// import styles from './event.module.css';
import web from '../images/WEBDEV.jpg'
import core from '../images/COREU.jpg'

const Recruitment = () => {
  return (
    <>
    <div className="hiring-container">
      <h2>🎉We Are Hiring!</h2>
      
      <div className="hiring-posters">
        <img
          src={core}
          alt="COREU Hiring Poster"
          className="poster"
        />
        <img
          src={web}
          alt="WEBDEV Hiring Poster"
          className="poster"
        />
      </div>
      
      <p>
        Artificial Intelligence is the future, and our student chapter is at the forefront of innovation. Join us to be part of something bigger!
      </p>
      
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdyeuHWwh4mdVUtD6mhvJn3n0J2pTMqK9kXhckAxkwrX9hXvA/viewform?usp=header " 
        target="_blank" 
        rel="noopener noreferrer"
        className="registerhir-btn"
      >
        Register Now
      </a>
    </div>





     {/* <div className='Time'>
        <section className='Time-wrapper'>
          <figure className='recruitment-figure'>
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </figure>
          <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>
          <div id='Time-title'>
            <span> Stay tuned for <br /> upcoming Recruitments</span>
          </div>
        </section>
      </div> */}
     
    </>
  )
}

export default Recruitment;
